import React from 'react';
import { createRoot } from 'react-dom/client';



import App from './App';
import * as serviceWorker from './tools/serviceWorker';

import './scss/themes/common.scss';

require(`./scss/themes/${process.env.REACT_APP_THEME}/index.scss`);

const container = document.getElementById('root')
const root = createRoot(container);
root.render(<App/>);
// If you want your app to work offline and load faster, you can change
serviceWorker.unregister();
